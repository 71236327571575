































































import { Component, InjectReactive, Watch } from "vue-property-decorator";
import Big from "big.js";
import { AbiItem } from "web3-utils";

import WrappedHydraStakingAbi from "@/contracts/WrappedHydraStaking.json";
import WrappedHydraAbi from "@/contracts/WrappedHydra.json";

import {
  ActionButtons,
  AmountField,
  ContractCallButton,
  InfoModal,
  InfoTable,
  StakingCore,
  StakingHeader,
} from "@/components";
import { getConfig } from "@/config";
import {
  formatUnitBalances,
  unitToToken,
  tokenToUnit,
  YEARLY_REWARD_WHYD_STAKING,
  WHYD_DECIMALS,
} from "@/utils";
import { StakingAction as Action } from "@/types";

@Component({
  components: {
    ActionButtons,
    AmountField,
    ContractCallButton,
    InfoModal,
    InfoTable,
    StakingHeader,
  },
})
export default class WrappedHydraStaking extends StakingCore {
  @InjectReactive("whydContractAddress") whydContractAddress!: string | null;

  private APR = Big(0);
  private pendingRewards: string | null = null;

  private wHydStakingProxyAddress = getConfig().wHydStakingProxyAddress;
  private wHydStakingLogicAddress = getConfig().wHydStakingLogicAddress;

  constructor() {
    super();
    this.amountTokenDecimal = WHYD_DECIMALS;
  }

  private async created(): Promise<void> {
    if (this.web3) {
      this.getContracts();
      await this.getSmartContractInfo();
    }
  }

  private getContracts(): void {
    this.stakingContract = new this.web3!.eth.Contract(
      WrappedHydraStakingAbi as AbiItem[],
      this.wHydStakingProxyAddress
    );
    this.tokenContract = new this.web3!.eth.Contract(
      WrappedHydraAbi as AbiItem[],
      this.whydContractAddress!
    );
  }

  @Watch("senderAccount")
  private async getSmartContractInfo(): Promise<void> {
    if (this.senderAccount) {
      await this.getAvailableBalance();
      await this.getAllowance();
      await this.getStake();
      await this.getPendingRewards();
    }
    await this.getTotalStake();
    this.calculateAPR();
  }

  private async sendSmartContractCall(): Promise<void> {
    if (
      this.currentAction === Action.STAKE ||
      this.currentAction === Action.UNSTAKE
    ) {
      this.startValidatingAmount = true;
      if (!this.amountIsValid) {
        return;
      }
    }

    let amountString = "";
    if (this.amountToken && this.currentAction !== Action.CLAIM) {
      amountString = tokenToUnit(this.amountToken!, WHYD_DECIMALS);
    }

    this.awaitingContractCall = true;
    try {
      switch (this.currentAction) {
        case Action.APPROVE:
          await this.approve();
          break;
        case Action.STAKE:
          await this.stake(amountString);
          break;
        case Action.UNSTAKE:
          await this.unstake(amountString);
          break;
        case Action.CLAIM:
          await this.claim();
          break;
      }
    } catch (e) {
      console.error(e);
      this.metamaskProblem = e.message;
    } finally {
      this.awaitingContractCall = false;
      this.getSmartContractInfo();
    }
  }

  private async getPendingRewards(): Promise<void> {
    const method = this.stakingContract.methods.estimateReward(
      this.senderAccount
    );
    this.pendingRewards = await method.call();
  }

  private calculateAPR(): void {
    if (this.totalStakeUnits) {
      const totalStakeToken = unitToToken(this.totalStakeUnits, WHYD_DECIMALS);
      this.APR = Big(YEARLY_REWARD_WHYD_STAKING).div(totalStakeToken).mul(100);
    }
  }

  private get infoItems(): { name: string; value: unknown }[] {
    return [
      {
        name: "WHYD Balance",
        value: {
          amount: `${formatUnitBalances(
            this.maxAmountUnits,
            WHYD_DECIMALS
          )} WHYD`,
          info: "Your current balance of WHYD that is fully available.",
        },
      },
      {
        name: "WHYD Staked",
        value: {
          amount: `${formatUnitBalances(
            this.currentStakeUnits,
            WHYD_DECIMALS
          )} WHYD`,
          info:
            "The amount of WHYD that you have locked up in the staking smart contract.",
        },
      },
      {
        name: "Claimable Rewards",
        value: {
          amount: `${formatUnitBalances(
            this.pendingRewards,
            WHYD_DECIMALS
          )} WHYD`,
          info: "The amount of rewards you received due to staking.",
        },
      },
      {
        name: "APR",
        value: {
          amount: `${this.APR.toFixed(2)} %`,
          info: `The annualized percentage rate. This value depends on the total locked stake.
            There is no guarantee that this will stay the same in the future.`,
        },
      },
      {
        name: "Total WHYD Locked for Staking",
        value: {
          amount: `${formatUnitBalances(
            this.totalStakeUnits,
            WHYD_DECIMALS
          )} WHYD`,
          info: "The total amount of WHYD staked by all participants.",
        },
      },
    ];
  }

  private Action: typeof Action = Action;
}
